
var controller = new ScrollMagic.Controller();
var scene;


var pageAnimations = (function ($) {

    return {
        init: function () {


            if (Modernizr.mq('(min-width: 768px)')) {

                TweenLite.set(".plane-image__wrap", {visibility: "visible"});

                // ---------------------
                // PLANE 5 ANIMATION
                // ---------------------
                var planePage  = "#plane-page";
                var pathPage   = '#plane-path-page';
                var tracePage  = '#plane-trace-page';
                var myEasePage = CustomEase.create("custom", "M0,0,C0,0,-0.016,0.28,0.332,0.418,0.456,0.467,0.494,0.49,0.568,0.592,0.759,0.855,1,1,1,1");
                var motionPage = MorphSVGPlugin.pathDataToBezier(pathPage, {align: planePage});
                TweenMax.set(planePage, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});

                TweenLite.set("#plane-page, #plane-trace-page", {visibility: "visible"});

                var tlPage = new TimelineLite();
                tlPage.add(
                    TweenMax.to(planePage, 3,
                        {
                            bezier: {
                                values:     motionPage,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEasePage

                        }
                    ), 0
                );
                tlPage.add(
                    TweenMax.from(tracePage, 3, {drawSVG: "0%", ease: myEasePage}), 0
                );



            }







        }
    };
})(jQuery);
// pageAnimations.init();



var homeAnimations = (function ($) {

    return {
        init: function () {






            if (Modernizr.mq('(min-width: 768px)')) {

                TweenLite.set(".plane-image__wrap", {visibility: "visible"});

                // <div style="position: fixed; top: 20px; left: 20px; z-index: 999;" id="anim-progress">
                // <label>progress</label>
                // <code id="progress">0</code>
                // </div>


                // ---------------------
                // PLANE 1 ANIMATION
                // ---------------------

                var plane1  = "#plane-1";
                var path1   = '#plane-path-1';
                var trace1  = '#plane-trace-1';
                var myEase1 = CustomEase.create("custom", "M0,0,C0,0,0.138,0.574,0.352,0.608,0.686,0.66,0.732,0.698,0.894,0.836,0.975,0.905,1,1,1,1");
                var motion1 = MorphSVGPlugin.pathDataToBezier(path1, {align: plane1});
                TweenMax.set(plane1, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});




                var tl = new TimelineLite();
                tl.add(
                    TweenMax.to(plane1, 7,
                        {
                            bezier: {
                                values:     motion1,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEase1

                        }
                    ), 0
                );
                tl.add(
                    TweenMax.from(trace1, 7, {drawSVG: "0%", ease: myEase1}), 0
                );
                scene = new ScrollMagic.Scene({triggerElement: "#trigger1", duration: 963, offset: 100})
                    .setTween(tl)
                    // .addIndicators({name: "1"})
                    .addTo(controller);


                // ---------------------
                // PLANE 2 ANIMATION
                // ---------------------
                var plane2  = "#plane-2";
                var path2   = '#plane-path-2';
                var trace2  = '#plane-trace-2';
                var myEase2 = CustomEase.create("custom", "M0,0,C0,0,0.238,0.174,0.368,0.406,0.464,0.578,0.698,0.624,0.85,0.718,0.978,0.797,1,1,1,1");
                var motion2 = MorphSVGPlugin.pathDataToBezier(path2, {align: plane2});
                TweenMax.set(plane2, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});

                var tl2 = new TimelineLite();
                tl2.add(
                    TweenMax.to(plane2, 7,
                        {
                            bezier: {
                                values:     motion2,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEase2

                        }
                    ), 0
                );
                tl2.add(
                    TweenMax.from(trace2, 7, {drawSVG: "0%", ease: myEase2}), 0
                );
                scene = new ScrollMagic.Scene({triggerElement: "#trigger2", duration: 963, offset: 300})
                    .setTween(tl2)
                    // .addIndicators({name: "2"})
                    .addTo(controller);


                // ---------------------
                // PLANE 3 ANIMATION
                // ---------------------
                var plane3  = "#plane-3";
                var path3   = '#plane-path-3';
                var trace3  = '#plane-trace-3';
                var myEase3 = CustomEase.create("custom", "M0,0,C0,0,0.238,0.174,0.368,0.406,0.464,0.578,0.698,0.624,0.85,0.718,0.978,0.797,1,1,1,1");
                var motion3 = MorphSVGPlugin.pathDataToBezier(path3, {align: plane3});
                TweenMax.set(plane3, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});

                var tl3 = new TimelineLite();
                tl3.add(
                    TweenMax.to(plane3, 7,
                        {
                            bezier: {
                                values:     motion3,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEase3

                        }
                    ), 0
                );
                tl3.add(
                    TweenMax.from(trace3, 7, {drawSVG: "0%", ease: myEase3}), 0
                );
                scene = new ScrollMagic.Scene({triggerElement: "#trigger3", duration: 963, offset: 0})
                    .setTween(tl3)
                    // .addIndicators({name: "3"})
                    .addTo(controller);


                // ---------------------
                // PLANE 4 ANIMATION
                // ---------------------
                var plane4  = "#plane-4";
                var path4   = '#plane-path-4';
                var trace4  = '#plane-trace-4';
                var myEase4 = CustomEase.create("custom", "M0,0 C0,0 0.057,0.294 0.142,0.388 0.404,0.676 0.746,0.672 0.87,0.796 0.976,0.902 1,1 1,1");
                var motion4 = MorphSVGPlugin.pathDataToBezier(path4, {align: plane4});
                TweenMax.set(plane4, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});

                var tl4 = new TimelineLite();
                tl4.add(
                    TweenMax.to(plane4, 7,
                        {
                            bezier: {
                                values:     motion4,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEase4

                        }
                    ), 0
                );
                tl4.add(
                    TweenMax.from(trace4, 7, {drawSVG: "0%", ease: myEase4}), 0
                );
                scene = new ScrollMagic.Scene({triggerElement: "#trigger4", duration: 1200, offset: 0})
                    .setTween(tl4)
                    // .addIndicators({name: "4"})
                    .addTo(controller);







            }





        }
    };
})(jQuery);
// homeAnimations.init();



var footerAnimations = (function ($) {

    return {
        init: function () {


            if (Modernizr.mq('(min-width: 768px)')) {

                TweenLite.set(".plane-image__wrap", {visibility: "visible"});

                // ---------------------
                // PLANE 5 ANIMATION
                // ---------------------
                var plane5  = "#plane-5";
                var path5   = '#plane-path-5';
                var trace5  = '#plane-trace-5';
                var myEase5 = Power2.easeOut;
                var motion5 = MorphSVGPlugin.pathDataToBezier(path5, {align: plane5});
                TweenMax.set(plane5, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});

                var tl5 = new TimelineLite();
                tl5.add(
                    TweenMax.to(plane5, 7,
                        {
                            bezier: {
                                values:     motion5,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEase5

                        }
                    ), 0
                );
                tl5.add(
                    TweenMax.from(trace5, 7, {drawSVG: "0%", ease: myEase5}), 0
                );
                scene = new ScrollMagic.Scene({triggerElement: "#trigger5", duration: 250, offset: 100})
                    .setTween(tl5)
                    // .addIndicators({name: "5"})
                    .addTo(controller);


                // ---------------------
                // PLANE 6 ANIMATION
                // ---------------------
                var guideCover = ".free-guide__form__title .guide-cover";
                var plane6wrap = ".form-plane__wrap";
                var plane6     = "#plane-6";
                var path6      = '#plane-path-6';
                var trace6     = '#plane-trace-6';
                var myEase6    = Power2.easeOut;
                var motion6    = MorphSVGPlugin.pathDataToBezier(path6, {align: plane6});
                TweenMax.set(plane6, {xPercent: -50, yPercent: -50, transformOrigin: "50% 50%"});

                var tl6 = new TimelineLite();
                tl6.add(
                    TweenMax.to(plane6, 7,
                        {
                            bezier: {
                                values:     motion6,
                                type:       "cubic",
                                autoRotate: 90
                            },
                            ease:   myEase6

                        }
                    ), 0
                );
                tl6.add(
                    TweenMax.from(trace6, 7, {drawSVG: "0%", ease: myEase6}), 0
                );
                tl6.add(
                    TweenMax.to(plane6wrap, 4, {zIndex: 4, ease: myEase6}), 3
                );

                tl6.add(
                    TweenMax.fromTo(guideCover, 1, {rotation: 0}, {rotation: 10}), 5
                );


                scene = new ScrollMagic.Scene({triggerElement: "#trigger6", duration: 200, offset: 0})
                    .setTween(tl6)
                    // .addIndicators({name: "6"})
                    .addTo(controller);
                // .on("progress", function (e) {
                //     jQuery("#progress").text(e.progress.toFixed(3));
                // });


            }







        }
    };
})(jQuery);
