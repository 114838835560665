var initEverywhere = (function ($) {
    var s;
    return {


        settings: {
            $formFreeGuide:       $('#form__free-guide'),
            $formContact:       $('#form__contact'),
            $formFreeGuideSubmit: $('.form__free-guide__submit'),
            $cookieBar: $('.cookie-bar')

        },


        init: function () {
            s = this.settings;
            this.bindUIActions();
        },


        bindUIActions: function () {

            if (s.$cookieBar.length) {
              var cookie = Cookies.get('cookie-bar');

              if(cookie === 'hidden-bar') {
                s.$cookieBar.hide();
              } else {
                s.$cookieBar.show();
              }

              $('.js-hide-cookie-bar-hide').on('click', function() {
                s.$cookieBar.fadeOut();
                Cookies.set('cookie-bar', 'hidden-bar', {expires: 90});
              });
            }

            s.$formFreeGuide.submit(function () {
                if ((s.$formFreeGuide.valid()) && $(this).find('button[type="submit"]').prop('disabled') === false) {
                   $(this).find('button[type="submit"]').css('cursor', 'not-allowed');
                   $(this).find('button[type="submit"]').html('Sending <span class="icon icon-spinner2"></span>');
                   $(this).find('button[type="submit"]').prop('disabled', true);
                } else { return false; }
            });

            s.$formContact.submit(function () {
                if ((s.$formContact.valid()) && $(this).find('button[type="submit"]').prop('disabled') === false) {
                    $(this).find('button[type="submit"]').css('cursor', 'not-allowed');
                    $(this).find('button[type="submit"]').html('Sending <span class="icon icon-spinner2"></span>');
                    $(this).find('button[type="submit"]').prop('disabled', true);
                } else { return false; }
            });


            $(".js-select2__enquiry").select2({
                minimumResultsForSearch: 10,
                width:                   '100%',
                placeholder:             "Enquiry type..."
            });

            $(".js-select2__enquiry--gforms select").select2({
                minimumResultsForSearch: 10,
                width:                   '100%',
                placeholder:             "Enquiry type..."
            });

            s.$formFreeGuide.validate({
                errorClass:     "is-error",
                validClass:     "is-success",
                errorPlacement: function (error, element) {
                    error.appendTo(element.parent("div"));
                },

                rules: {
                    // simple rule, converted to {required:true}
                    fg_first_name: "required",
                    fg_last_name:  "required",
                    fg_company:    "required",
                    fg_phone:      {
                        required: true,
                        digits:   true
                    },
                    fg_email:      {
                        required: true,
                        email:    true
                    }
                }
            });


            $("#form__contact").validate({
                errorLabelContainer: false,
                errorClass:          "is-error",
                validClass:          "is-success",
                errorPlacement:      function (error, element) {
                    error.appendTo(element.parent("div"));
                },
                rules: {
                    // simple rule, converted to {required:true}
                    cf_first_name: "required",
                    cf_last_name:  "required",
                    cf_company:    "required",
                    cf_phone:      {
                        required: true,
                        digits:   true
                    },
                    cf_email:      {
                        required: true,
                        email:    true
                    },
                    cf_enquiry: 'required',
                    cf_message:    "required"
                }
            });

        }


    };
})(jQuery);
initEverywhere.init();


// ----------------------------------
//  Unfocus button after click
// ----------------------------------
//
var btnUnfocus = (function ($) {
    return {
        init: function () {
            $(".btn").mouseup(function () {
                $(this).blur();
            });
        }
    };
})(jQuery);
btnUnfocus.init();


// ----------------------------------
// HAMBURGER TOGGLE
// ----------------------------------
//
var hamburgerToggle = (function ($) {

    return {
        init: function () {

            var resizeTimer;


            $(window).scroll(function () {
                if (Modernizr.mq('(min-width: 992px)')) {
                    var scroll = $(window).scrollTop();

                    if (scroll >= 10) {
                        $("#top-bar").addClass("smallhead");
                    } else {
                        $("#top-bar").removeClass("smallhead");
                    }
                }
            });

            $(window).on('resize', function (e) {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function () {
                    if (Modernizr.mq('(min-width: 992px)')) {
                        $('.nav-primary').css('opacity', '1');
                    } else {
                        $('.top-bar').removeClass('smallhead');
                    }
                }, 250);
            });

            $('.hamburger').click(function () {
                if ($(this).hasClass('is-active')) {
                    hamburgerToggle.menuFadeOut();
                } else {
                    hamburgerToggle.menuFadeIn();
                }
            });

        },

        menuFadeIn: function () {
            $('body').addClass('scroll-lock');
            $('.nav-primary').addClass('is-active');
            $('.hamburger').addClass('is-active');
            TweenMax.fromTo('.nav-primary', 0.1, {opacity: 0}, {opacity: 1});
            TweenMax.staggerFromTo('.top-menu li, .getFreeGuide__wrap', 0.3, {opacity: 0}, {opacity: 1}, 0.05);
        },

        menuFadeOut: function () {
            TweenMax.fromTo('.nav-primary', 0.3, {opacity: 1}, {
                opacity: 0, onComplete: function () {
                    $('body').removeClass('scroll-lock');
                    $('.nav-primary').removeClass('is-active');
                    $('.hamburger').removeClass('is-active');
                }
            });
        }


    };
})(jQuery);
hamburgerToggle.init();


// ----------------------------------
//  Get free guide
// ----------------------------------
//
var freeGuideScroll = (function ($) {
    return {
        init: function () {

            $(".home__findout-more").click(function () {
                TweenLite.to(window, 1, {scrollTo: {y: "#form__free-guide", offsetY: 120}});
            });

            $('.getFreeGuide').click(function () {


                if (Modernizr.mq('(min-width: 992px)')) {

                    TweenLite.to(window, 1, {scrollTo: {y: "#form__free-guide", offsetY: 120}});

                } else {

                    hamburgerToggle.menuFadeOut();
                    setTimeout(function () {
                        TweenLite.to(window, 1, {scrollTo: {y: "#form__free-guide", offsetY: 120}});
                    }, 250);

                    console.log('mobile');

                }


            });


        }
    };
})(jQuery);
freeGuideScroll.init();


// ----------------------------------
// Console Log Form values just for checking
// TODO: remove this on production server;
// ----------------------------------
//
var consoleLogFormValues = (function ($) {

    return {
        init: function () {

            $('form').on('submit', function (e) { //use on if jQuery 1.7+
                e.preventDefault();  //prevent form from submitting
                var data = $(this).find(":input").serializeArray();
                console.log(data); //use the console for debugging, F12 in Chrome, not alerts
            });

        }
    };
})(jQuery);
//consoleLogFormValues.init();
